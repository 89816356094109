<template>


	<div class="wrap p-phonics">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="$router.go(-1)"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">Fun Phonics</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="book-list tabs">
					<ul class="tab-nav">
						<li v-for="(course, index) in courseList" :class="{ 'open': (course.isOpen), 'active': (course.isActive) }">
							<button type="button">{{ course.shortTitle }}</button>
						</li>
					</ul>
					<div class="tab-cont" v-for="(course, index) in courseList" :class="{ 'active' : course.isOpen }">
						<ul>
							<!-- 열람 가능한 컨텐츠는 open 클래스 -->
							<!-- 진행중인 컨텐츠는 ing 클래스 -->
<!--							<li class="open">
								<a href="#">
									<div class="thumb" style="background-image: url(/assets/images/learning/img_phonics_book_01.jpg);"></div>
									<strong class="book-vol">Book1</strong>
								</a>
							</li>
							<li class="open ing">
								<a href="#">
									<div class="thumb" style="background-image: url(/assets/images/learning/img_phonics_book_02.jpg);"></div>
									<strong class="book-vol">Book2</strong>
									<span class="ing-text">학습중</span>
								</a>
							</li>-->
							<li v-for="(main, idx) in course.eduMainList" :class="{ 'open': (main.isOpen), 'ing': (main.isActive) }">
								<template v-if="main.isOpen">
									<router-link :to="{ name: 'phonics-contents', query: { emIdx: main.emIdx } }">
										<div class="thumb" :style="'background-image: url(' + main.thumbnail + ');'"></div>
										<strong class="book-vol" style="font-size: 16px">{{ main.shortTitle }}</strong>
										<span class="ing-text" v-if="main.isActive">학습중</span>
									</router-link>
								</template>
								<template>
									<strong class="book-vol" style="font-size: 16px">{{ main.shortTitle }}</strong>
									<span class="ing-text" v-if="main.isActive">학습중</span>
								</template>
							</li>
						</ul>
					</div>

				</div>
			</div>
		</div>
	</div>


</template>

<script>
import $ from 'jquery'

export default {
    name: "phonicsList",
    components: {

    },
    data() {
        return {
            maxAttendance: 0,
            coAccountVO: Object,

            params: this.pageUtils.Params("shortTitle,ASC"),
            // eslint-disable-next-line no-mixed-spaces-and-tabs
            courseList: [],
	        mainList: [],

	        // 최신 교육내용 조회
	        guageNum: 0,
	        guageMax: 7,
	        guagePercen: 0,
            recentAnswer: null,
        }
    },
    created() {

	    this.$eventBus.$emit("startProgress")

        this.requireLogin(this)
        this.getOpenCourse();
    },
    mounted() {

        $(".modal-open").on("click", function () {
            var attr = $(this).attr("data-pop")
            $("#" + attr).fadeIn()
        })
        $(".modal-close").on("click", function () {
            $(".modal").fadeOut()
        })

        this.coAccountVO = this.$session.get("HWSSession")

    },
    methods: {


        /**
         * 사용자의 열린 교과정 조회
         */
        getOpenCourse() {

            const self = this

            this.params.openPrevCourse = false
            this.params.openPrevMain = true
            this.params.cateCode = "200102"
            this.axiosCaller.get(self, this.APIs.EDU_COURSE + "/getOpenCourseList", this.params, (res) => {

                const result = res.data
	            console.log( result )
                if (result.status === "ok") {

                    this.courseList = result.list
	                for (let i = this.courseList.length - 1; i >= 0; i--) {
		                if ( this.courseList[i].isOpen ) {
			                this.courseList[i].isActive = true;
							break;
		                }
	                }

					self.$nextTick(() => {
						$(".tab-nav li").on("click", function () {
							var nav = $(this);
							var idx = nav.index();
							var wrap = nav.closest(".tabs");
							wrap.find(".tab-cont").removeClass("active");
							wrap.find(".tab-nav li").removeClass("active");
							wrap.find(".tab-cont:eq(" + idx + ")").addClass("active");
							nav.addClass("active");
						});


						this.getLastStep();
					})

                } else {
                    this.swalUtils.gritter(result.status, result.msg, "error")
                }

	            this.$eventBus.$emit("doneProgress")
            })

        },


        /**
         * 사용자의 최근 답변 데이터 조회
         */
        getLastStep() {

	        const self = this
	        this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getLastStep", {
		        userId: this.getLoginId(),
		        cateCode: "200102",
	        }, res => {
		        const result = res.data
		        if (result.status === "ok") {

			        self.recentAnswer = {
				        answerList: result.answerList,
				        eduCourseVO: result.eduCourseVO,
				        // eduMainVO: result.eduMainVO,
				        // contentList: result.eduMainVO.contentList,
			        }

			        if ( result.eduMainVO != null ) {
				        self.recentAnswer.eduMainVO = result.eduMainVO;
				        self.recentAnswer.contentList = result.eduMainVO.contentList;

						self.courseList.forEach(course => {
							course.eduMainList.forEach(value => {
								if ( value.emIdx === result.eduMainVO.emIdx ) {
									value.isActive = true
								}
							})
						})
			        }
		        }
	        })
        },



	    goContentList (_emIdx, _level) {

			this.$router.push({
				path: '/phonics/contentList/',
				query: {
					emIdx: _emIdx,
					level: _level,
				}
			})

	    },


    }
}
</script>

<style scoped>

</style>